import type {File, Image} from '~/types/sofie'
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: number | string; output: number | string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Email: { input: string; output: string; }
  File: { input: File; output: File; }
  Iban: { input: string; output: string; }
  Image: { input: Image; output: Image; }
  JSON: { input: any; output: any; }
  Null: { input: null; output: null; }
  PrivateFile: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Uuid: { input: string; output: string; }
};

export type Address = {
  __typename?: 'Address';
  capabilities: Array<Maybe<Capability>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  geodata?: Maybe<Scalars['JSON']['output']>;
  house_number?: Maybe<Scalars['String']['output']>;
  house_number_suffix?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type Banner = {
  __typename?: 'Banner';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  subtitel?: Maybe<Scalars['String']['output']>;
  titel_weergeven?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type BannerActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type BannerImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Bedrijf = {
  __typename?: 'Bedrijf';
  active_image?: Maybe<Scalars['Image']['output']>;
  adres?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  e_mailadres?: Maybe<Scalars['Email']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  land?: Maybe<Scalars['String']['output']>;
  plaats?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  sectoren: Array<Maybe<Sector>>;
  sectoren_count?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  telefoonnummer?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};


export type BedrijfActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type BedrijfImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type BedrijfPagination = {
  __typename?: 'BedrijfPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Bedrijf>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type Bericht = {
  __typename?: 'Bericht';
  active_image?: Maybe<Scalars['Image']['output']>;
  ambition?: Maybe<Werkveld>;
  capabilities: Array<Maybe<Capability>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  color?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  datum: Scalars['Date']['output'];
  einddatum?: Maybe<Scalars['Date']['output']>;
  extra_text?: Maybe<Scalars['String']['output']>;
  extra_title?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  /** Wanneer "ja" gekozen is zal dit bericht bovenaan in de wekelijkse nieuwsbrief verschijnen. */
  nieuwsbrief_prioriteit?: Maybe<Scalars['Boolean']['output']>;
  omgevingen: Array<Maybe<Omgeving>>;
  omgevingen_count?: Maybe<Scalars['Int']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  tekst: Scalars['String']['output'];
  themas?: Maybe<Array<Maybe<Thema>>>;
  themas_count?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  uitgelicht?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type BerichtActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type BerichtImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type BerichtPagination = {
  __typename?: 'BerichtPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Bericht>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type Capability = {
  __typename?: 'Capability';
  name: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  active_image?: Maybe<Scalars['Image']['output']>;
  ancestors?: Maybe<Array<Maybe<Category>>>;
  capabilities: Array<Maybe<Capability>>;
  children?: Maybe<Array<Maybe<Category>>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  descendants?: Maybe<Array<Maybe<Category>>>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  name: Scalars['String']['output'];
  parent?: Maybe<Category>;
  parent_id?: Maybe<Scalars['Int']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type CategoryActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Comment = {
  __typename?: 'Comment';
  capabilities: Array<Maybe<Capability>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  has_liked: Scalars['Boolean']['output'];
  likes_count: Scalars['Int']['output'];
  parent_comment?: Maybe<Comment>;
  published?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  topic: Topic;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User_F9085a4652e63fc996a11189dae3f3dd>;
  uuid: Scalars['Uuid']['output'];
};

export type Contactformulier = {
  __typename?: 'Contactformulier';
  bedrijf: Scalars['String']['output'];
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  e_mail: Scalars['Email']['output'];
  id: Scalars['ID']['output'];
  naam: Scalars['String']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  telefoon?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vraag?: Maybe<Scalars['String']['output']>;
};

export type ContentBlock = {
  __typename?: 'ContentBlock';
  content: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Evenement = {
  __typename?: 'Evenement';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  einddatum?: Maybe<Scalars['Date']['output']>;
  eindtijd: Scalars['String']['output'];
  externe_url?: Maybe<Scalars['String']['output']>;
  groepen?: Maybe<Array<Maybe<Groep>>>;
  groepen_count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  locatie: Scalars['String']['output'];
  omgevingen: Array<Maybe<Omgeving>>;
  omgevingen_count?: Maybe<Scalars['Int']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  startdatum: Scalars['Date']['output'];
  starttijd: Scalars['String']['output'];
  tekst?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  uitgelicht?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type EvenementActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type EvenementImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Form = {
  __typename?: 'Form';
  fields?: Maybe<Array<Maybe<FormField>>>;
  id: Scalars['ID']['output'];
  schema?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type FormData = {
  __typename?: 'FormData';
  fields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
};

export type FormField = {
  __typename?: 'FormField';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unique?: Maybe<Scalars['Boolean']['output']>;
};

export type Gebied = {
  __typename?: 'Gebied';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type GebiedActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type GebiedImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type GlobalSearch = {
  __typename?: 'GlobalSearch';
  ambitions: Array<Werkveld>;
  events: Array<Evenement>;
  news: Array<Bericht>;
};

export type Groep = {
  __typename?: 'Groep';
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  documenten?: Maybe<Array<Maybe<Scalars['File']['output']>>>;
  /** Melding op het dashboard die toelicht met wie er contact opgenomen kan worden voor het verwijderen van documenten. */
  documenten_verwijder_melding?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export enum LikeableItemEnum {
  Comment = 'Comment',
  Topic = 'Topic'
}

export type Link = {
  __typename?: 'Link';
  active_image?: Maybe<Scalars['Image']['output']>;
  afbeelding?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  externe_url: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  intro: Scalars['String']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};


export type LinkActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type LinkAfbeeldingArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type LinkImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export enum LocaleEnum {
  De = 'de',
  En = 'en',
  Nl = 'nl'
}

export type Mailinglijst = {
  __typename?: 'Mailinglijst';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  toelichting?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zichtbaar_voor?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};


export type MailinglijstActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type MailinglijstImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Medewerker = {
  __typename?: 'Medewerker';
  achternaam: Scalars['String']['output'];
  active_image?: Maybe<Scalars['Image']['output']>;
  afdeling: Scalars['String']['output'];
  bedrijf?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  e_mailadres?: Maybe<Scalars['String']['output']>;
  functie?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  telefoonnummer?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};


export type MedewerkerActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type MedewerkerImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Melding = {
  __typename?: 'Melding';
  auteur: User;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  groepen?: Maybe<Array<Maybe<Groep>>>;
  groepen_count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  tekst?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type Menu = {
  __typename?: 'Menu';
  items?: Maybe<Array<Maybe<MenuItem>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  children?: Maybe<Array<Maybe<MenuItem>>>;
  scope?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  apply?: Maybe<Scalars['Boolean']['output']>;
  createCommentForTopic: Comment;
  createTopic?: Maybe<Topic>;
  deleteComment?: Maybe<Scalars['Null']['output']>;
  deleteTopic?: Maybe<Scalars['Null']['output']>;
  disableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  enableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  finishOnboarding?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  generateTwoFactorSecret?: Maybe<TwoFactorSecret>;
  login?: Maybe<Scalars['String']['output']>;
  logout?: Maybe<Scalars['Boolean']['output']>;
  markNotificationRead: Scalars['Boolean']['output'];
  requestPasswordReset?: Maybe<Scalars['Boolean']['output']>;
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  storeUserInterests?: Maybe<Scalars['Null']['output']>;
  submitContactForm?: Maybe<Scalars['Boolean']['output']>;
  submitForm?: Maybe<Scalars['Null']['output']>;
  submitPollTopicAnswer: Scalars['Boolean']['output'];
  toggleFavoriteTopic: Scalars['Boolean']['output'];
  toggleLikeItem: Scalars['Boolean']['output'];
  updateComment?: Maybe<Comment>;
  updateTopic?: Maybe<Topic>;
  updateUserDetails?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  useInvite?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationApplyArgs = {
  cv: Scalars['Upload']['input'];
  motivatie: Scalars['String']['input'];
  naam: Scalars['String']['input'];
  telefoonnummer: Scalars['String']['input'];
  title: Scalars['Email']['input'];
  vacature: Scalars['String']['input'];
};


export type MutationCreateCommentForTopicArgs = {
  parent_comment_uuid?: InputMaybe<Scalars['Uuid']['input']>;
  text: Scalars['String']['input'];
  topic_uuid: Scalars['Uuid']['input'];
};


export type MutationCreateTopicArgs = {
  allow_multiple_answers?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<TopicImageInput>;
  poll_options?: InputMaybe<Array<InputMaybe<PollOptionInput>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  themes: Array<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  type: TopicTypeEnum;
};


export type MutationDeleteCommentArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type MutationDeleteTopicArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type MutationEnableTwoFactorArgs = {
  otp: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  two_factor_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMarkNotificationReadArgs = {
  notification_id: Scalars['Uuid']['input'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['Email']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  password_confirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationStoreUserInterestsArgs = {
  interests: Array<Scalars['ID']['input']>;
};


export type MutationSubmitContactFormArgs = {
  company: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  question: Scalars['String']['input'];
};


export type MutationSubmitFormArgs = {
  form_data: Scalars['String']['input'];
  form_id: Scalars['ID']['input'];
  g_recaptcha_response?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSubmitPollTopicAnswerArgs = {
  option_uuids: Array<Scalars['Uuid']['input']>;
  topic_uuid: Scalars['Uuid']['input'];
};


export type MutationToggleFavoriteTopicArgs = {
  topic_uuid: Scalars['Uuid']['input'];
};


export type MutationToggleLikeItemArgs = {
  item_type: LikeableItemEnum;
  item_uuid: Scalars['Uuid']['input'];
};


export type MutationUpdateCommentArgs = {
  text: Scalars['String']['input'];
  uuid: Scalars['Uuid']['input'];
};


export type MutationUpdateTopicArgs = {
  allow_multiple_answers?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<TopicImageInput>;
  poll_options?: InputMaybe<Array<InputMaybe<PollOptionInput>>>;
  text?: InputMaybe<Scalars['String']['input']>;
  themes: Array<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  type: TopicTypeEnum;
  uuid: Scalars['Uuid']['input'];
};


export type MutationUpdateUserDetailsArgs = {
  company_name?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  interests: Array<Scalars['ID']['input']>;
  last_name: Scalars['String']['input'];
};


export type MutationUseInviteArgs = {
  email: Scalars['Email']['input'];
  invite: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Nieuwsbrief = {
  __typename?: 'Nieuwsbrief';
  active_image?: Maybe<Scalars['Image']['output']>;
  berichten?: Maybe<Array<Maybe<Bericht>>>;
  berichten_count?: Maybe<Scalars['Int']['output']>;
  button_tekst?: Maybe<Scalars['String']['output']>;
  button_url?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  /** Dit veld toont wanneer het JA is geen banner afbeelding en logo in de nieuwsbrief. Je kunt dan in het tekst vak een afbeelding plaatsen als uitnodiging. Bij NEE, de standaardwaarde, worden de banner en het logo getoond. */
  is_extra?: Maybe<Scalars['Boolean']['output']>;
  ontvangers: Array<Maybe<Scalars['String']['output']>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  tekst: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  verzenddatum?: Maybe<Scalars['DateTime']['output']>;
};


export type NieuwsbriefActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type NieuwsbriefImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Notification = {
  __typename?: 'Notification';
  created_at: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['Uuid']['output'];
  read_at?: Maybe<Scalars['DateTime']['output']>;
  type: Scalars['String']['output'];
};

export type Omgeving = {
  __typename?: 'Omgeving';
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type Page = {
  __typename?: 'Page';
  active_image?: Maybe<Scalars['Image']['output']>;
  children?: Maybe<Array<Maybe<Page>>>;
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  parent?: Maybe<Page>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  template?: Maybe<Template>;
  template_name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type PageActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type PageImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Point = {
  __typename?: 'Point';
  beschrijving?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  left?: Maybe<Scalars['Float']['output']>;
  left_open?: Maybe<Scalars['Float']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  top?: Maybe<Scalars['Float']['output']>;
  top_open?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type PollOption = {
  __typename?: 'PollOption';
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
};

export type PollOptionInput = {
  position: Scalars['Int']['input'];
  text: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['Uuid']['input']>;
};

export type PollSubmissionOverview = {
  __typename?: 'PollSubmissionOverview';
  count: Scalars['Int']['output'];
  option?: Maybe<PollOption>;
};

export type Query = {
  __typename?: 'Query';
  ambition?: Maybe<Werkveld>;
  ambitions: Array<Werkveld>;
  article?: Maybe<Bericht>;
  articles?: Maybe<BerichtPagination>;
  businessCouncils: Array<Page>;
  committees: Array<Page>;
  employees: Array<Medewerker>;
  event?: Maybe<Evenement>;
  events: Array<Evenement>;
  favoriteTopics?: Maybe<TopicPagination>;
  form?: Maybe<Form>;
  getAllNotifications: Array<Notification>;
  globalSearch?: Maybe<GlobalSearch>;
  me?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  member?: Maybe<Bedrijf>;
  members?: Maybe<BedrijfPagination>;
  menu?: Maybe<Menu>;
  menus?: Maybe<Array<Maybe<Menu>>>;
  myTopics?: Maybe<TopicPagination>;
  networkingClubs: Array<Page>;
  networkingGroups: Array<Page>;
  newsItem?: Maybe<Bericht>;
  page?: Maybe<Page>;
  questions: Array<Vraag>;
  sectors: Array<Sector>;
  settings?: Maybe<Array<Maybe<Setting>>>;
  theme: Thema;
  themeDownloads: Array<ThemeDownload>;
  themeNews?: Maybe<BerichtPagination>;
  themes: Array<Thema>;
  topic: Topic;
  topicComments: Array<Comment>;
  topics?: Maybe<TopicPagination>;
  vacancies: Array<Vacature>;
  vacancy?: Maybe<Vacature>;
};


export type QueryAmbitionArgs = {
  slug: Scalars['String']['input'];
};


export type QueryAmbitionsArgs = {
  for_app?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArticleArgs = {
  slug: Scalars['String']['input'];
};


export type QueryArticlesArgs = {
  ambition_id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEventArgs = {
  slug: Scalars['String']['input'];
};


export type QueryFavoriteTopicsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGlobalSearchArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};


export type QueryMemberArgs = {
  slug: Scalars['String']['input'];
};


export type QueryMembersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sector?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryMenuArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMenusArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMyTopicsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNewsItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPageArgs = {
  locale?: InputMaybe<LocaleEnum>;
  segments?: InputMaybe<Scalars['String']['input']>;
};


export type QueryThemeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryThemeDownloadsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryThemeNewsArgs = {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTopicArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type QueryTopicCommentsArgs = {
  sort?: InputMaybe<TopicSortDirectionEnum>;
  topic: Scalars['Uuid']['input'];
};


export type QueryTopicsArgs = {
  ambition?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<TopicSortDirectionEnum>;
};


export type QueryVacancyArgs = {
  slug: Scalars['String']['input'];
};

export type Reactie = {
  __typename?: 'Reactie';
  active_image?: Maybe<Scalars['Image']['output']>;
  auteur: User;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  melding: Melding;
  published?: Maybe<Scalars['Boolean']['output']>;
  tekst: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};


export type ReactieActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type ReactieImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Sector = {
  __typename?: 'Sector';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  kleur?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type SectorActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type SectorImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Seo = {
  __typename?: 'Seo';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_follow?: Maybe<Scalars['Boolean']['output']>;
  meta_index?: Maybe<Scalars['Boolean']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type Setting = {
  __typename?: 'Setting';
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type Template = TemplateAboutUs | TemplateContact | TemplateEngels | TemplateHome | TemplateIntroimage | TemplateIntrosmallimage | TemplateIntrowithoutimage | TemplateLidWorden | TemplateStandaard | TemplateWithoutintrolargeimage;

export type TemplateAboutUs = {
  __typename?: 'TemplateAboutUs';
  capabilities: Array<Maybe<Capability>>;
  cirkel_tekst?: Maybe<Scalars['String']['output']>;
  cirkel_titel?: Maybe<Scalars['String']['output']>;
  content_title?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  extra_tekst?: Maybe<Scalars['String']['output']>;
  extra_titel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  member_text?: Maybe<Scalars['String']['output']>;
  member_title?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  tekst?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vacancies_text?: Maybe<Scalars['String']['output']>;
  vacancies_title?: Maybe<Scalars['String']['output']>;
};

export type TemplateContact = {
  __typename?: 'TemplateContact';
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  tekst: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateEngels = {
  __typename?: 'TemplateEngels';
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  tekst?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateHome = {
  __typename?: 'TemplateHome';
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  extra_tekst?: Maybe<Scalars['String']['output']>;
  extra_tekst_1?: Maybe<Scalars['String']['output']>;
  extra_tekst_2?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  punten?: Maybe<Array<Maybe<Point>>>;
  punten_count?: Maybe<Scalars['Int']['output']>;
  sector_tekst?: Maybe<Scalars['String']['output']>;
  sector_titel?: Maybe<Scalars['String']['output']>;
  teaser: Scalars['String']['output'];
  tekst: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateIntroimage = {
  __typename?: 'TemplateIntroimage';
  capabilities: Array<Maybe<Capability>>;
  content_title?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intro?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateIntrosmallimage = {
  __typename?: 'TemplateIntrosmallimage';
  capabilities: Array<Maybe<Capability>>;
  content_title?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intro?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateIntrowithoutimage = {
  __typename?: 'TemplateIntrowithoutimage';
  capabilities: Array<Maybe<Capability>>;
  content_title?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intro?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateLidWorden = {
  __typename?: 'TemplateLidWorden';
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  tekst?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateStandaard = {
  __typename?: 'TemplateStandaard';
  capabilities: Array<Maybe<Capability>>;
  content_title?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  tekst?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateWithoutintrolargeimage = {
  __typename?: 'TemplateWithoutintrolargeimage';
  capabilities: Array<Maybe<Capability>>;
  content_title?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Thema = {
  __typename?: 'Thema';
  active_image?: Maybe<Scalars['Image']['output']>;
  ancestors: Array<Thema>;
  berichten_count?: Maybe<Scalars['Int']['output']>;
  capabilities: Array<Maybe<Capability>>;
  children: Array<Thema>;
  children_count?: Maybe<Scalars['Int']['output']>;
  content_blocks: Array<ContentBlock>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  descendants: Array<Thema>;
  documenten?: Maybe<Array<Maybe<Scalars['File']['output']>>>;
  externe_links?: Maybe<Array<Maybe<Link>>>;
  externe_links_count?: Maybe<Scalars['Int']['output']>;
  hoofdpagina?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  parent?: Maybe<Thema>;
  published?: Maybe<Scalars['Boolean']['output']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  tekst?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  uitgelicht?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type ThemaActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type ThemaImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type ThemeDownload = {
  __typename?: 'ThemeDownload';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Tool = {
  __typename?: 'Tool';
  abonnementen?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  externe_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  tekst?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  /** Kies ja om (geassocieerde) leden altijd toegang te geven tot deze tool. */
  toegang_leden?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type ToolActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type ToolImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Topic = {
  __typename?: 'Topic';
  active_image?: Maybe<Scalars['Image']['output']>;
  allow_multiple_answers?: Maybe<Scalars['Boolean']['output']>;
  capabilities: Array<Maybe<Capability>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  comments_count: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  favorited: Scalars['Boolean']['output'];
  has_liked: Scalars['Boolean']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  latest_comment?: Maybe<Scalars['DateTime']['output']>;
  likes_count: Scalars['Int']['output'];
  owner?: Maybe<User_F9085a4652e63fc996a11189dae3f3dd>;
  pinned?: Maybe<Scalars['Boolean']['output']>;
  poll_options?: Maybe<Array<Maybe<PollOption>>>;
  poll_submission: Array<Scalars['Uuid']['output']>;
  poll_submissions_count: Scalars['Int']['output'];
  poll_submissions_overview?: Maybe<Array<Maybe<PollSubmissionOverview>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  themas?: Maybe<Array<Maybe<Werkveld>>>;
  themas_count?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  type: TopicTypeEnum;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['Uuid']['output'];
  views_count: Scalars['Int']['output'];
};


export type TopicActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type TopicImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type TopicImageInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type TopicPagination = {
  __typename?: 'TopicPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data?: Maybe<Array<Maybe<Topic>>>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export enum TopicSortDirectionEnum {
  New = 'New',
  Popular = 'Popular',
  Relevant = 'Relevant'
}

export enum TopicTypeEnum {
  Message = 'Message',
  Poll = 'Poll'
}

export type TwoFactorSecret = {
  __typename?: 'TwoFactorSecret';
  app: Scalars['String']['output'];
  email: Scalars['Email']['output'];
  secret: Scalars['String']['output'];
};

export type UitgelichteItem = {
  __typename?: 'UitgelichteItem';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type UitgelichteItemActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type UitgelichteItemImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type User = {
  __typename?: 'User';
  _?: Maybe<Scalars['String']['output']>;
};

export type User_07e75d37dfdb1fad1b23e74888c041b4 = {
  __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4';
  abonnementen?: Maybe<Array<Maybe<Tool>>>;
  abonnementen_count?: Maybe<Scalars['Int']['output']>;
  abonnementsvorm?: Maybe<Scalars['String']['output']>;
  active_image?: Maybe<Scalars['Image']['output']>;
  afzet__en_importgebieden?: Maybe<Array<Maybe<Gebied>>>;
  afzet__en_importgebieden_count?: Maybe<Scalars['Int']['output']>;
  avatar: Scalars['String']['output'];
  bedrijf?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Email']['output'];
  facebook?: Maybe<Scalars['String']['output']>;
  finished_onboarding?: Maybe<Scalars['Boolean']['output']>;
  first_name: Scalars['String']['output'];
  groepen?: Maybe<Array<Maybe<Groep>>>;
  groepen_count?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  interests?: Maybe<Array<Maybe<Werkveld>>>;
  interests_count?: Maybe<Scalars['Int']['output']>;
  is_gfh_lid?: Maybe<Scalars['Boolean']['output']>;
  last_name: Scalars['String']['output'];
  linkedin?: Maybe<Scalars['String']['output']>;
  mailinglijsten?: Maybe<Array<Maybe<Mailinglijst>>>;
  mailinglijsten_count?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nieuwsbrief?: Maybe<Scalars['Boolean']['output']>;
  notification_count?: Maybe<Scalars['Int']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  reactie_meldingen_ontvangen?: Maybe<Scalars['Boolean']['output']>;
  relatiecode?: Maybe<Scalars['String']['output']>;
  relatietype?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  role_id: Scalars['ID']['output'];
  telefoonnummer?: Maybe<Scalars['String']['output']>;
  two_factor_enabled?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
  verantwoordelijkheidsgebieden?: Maybe<Array<Maybe<Verantwoordelijkheidsgebied>>>;
  verantwoordelijkheidsgebieden_count?: Maybe<Scalars['Int']['output']>;
  voorvoegsel?: Maybe<Scalars['String']['output']>;
};


export type User_07e75d37dfdb1fad1b23e74888c041b4Active_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type User_07e75d37dfdb1fad1b23e74888c041b4AvatarArgs = {
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type User_07e75d37dfdb1fad1b23e74888c041b4ImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type User_F9085a4652e63fc996a11189dae3f3dd = {
  __typename?: 'User_f9085a4652e63fc996a11189dae3f3dd';
  avatar: Scalars['String']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
};


export type User_F9085a4652e63fc996a11189dae3f3ddAvatarArgs = {
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Vacature = {
  __typename?: 'Vacature';
  aantal_uren?: Maybe<Scalars['String']['output']>;
  active_image?: Maybe<Scalars['Image']['output']>;
  beheerder?: Maybe<Medewerker>;
  beschrijving?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  dienstverband?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  opleidingsniveau?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  reactie?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  verwachtingen?: Maybe<Scalars['String']['output']>;
  wat_ga_je_doen?: Maybe<Scalars['String']['output']>;
  werkomgeving?: Maybe<Scalars['String']['output']>;
  wij_bieden?: Maybe<Scalars['String']['output']>;
};


export type VacatureActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type VacatureImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Vacatureformulier = {
  __typename?: 'Vacatureformulier';
  aanhef?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  cv?: Maybe<Array<Maybe<Scalars['File']['output']>>>;
  id: Scalars['ID']['output'];
  motivatie?: Maybe<Scalars['String']['output']>;
  naam?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  telefoonnummer?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vacature?: Maybe<Vacature>;
};

export type Verantwoordelijkheidsgebied = {
  __typename?: 'Verantwoordelijkheidsgebied';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type VerantwoordelijkheidsgebiedActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type VerantwoordelijkheidsgebiedImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Vraag = {
  __typename?: 'Vraag';
  antwoord: Scalars['String']['output'];
  capabilities: Array<Maybe<Capability>>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Waarschuwing = {
  __typename?: 'Waarschuwing';
  capabilities: Array<Maybe<Capability>>;
  classification: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  date_of_case: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  notifying_country: Scalars['String']['output'];
  product_category: Scalars['String']['output'];
  published?: Maybe<Scalars['Boolean']['output']>;
  risk_decision: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type Werkveld = {
  __typename?: 'Werkveld';
  active_image?: Maybe<Scalars['Image']['output']>;
  ancestors: Array<Werkveld>;
  capabilities: Array<Maybe<Capability>>;
  children: Array<Werkveld>;
  color?: Maybe<Scalars['String']['output']>;
  content_tekst?: Maybe<Scalars['String']['output']>;
  content_titel?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  descendants: Array<Werkveld>;
  description?: Maybe<Scalars['String']['output']>;
  downloads?: Maybe<Array<Maybe<Scalars['File']['output']>>>;
  hoofdpagina?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  icoon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['Image']['output']>>>;
  order?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<Werkveld>;
  published?: Maybe<Scalars['Boolean']['output']>;
  seo?: Maybe<Seo>;
  short_text?: Maybe<Scalars['String']['output']>;
  show_in_app?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  tekst?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};


export type WerkveldActive_ImageArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};


export type WerkveldImagesArgs = {
  blur?: InputMaybe<Scalars['Int']['input']>;
  bri?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  fm?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type ApplyMutationVariables = Exact<{
  naam: Scalars['String']['input'];
  title: Scalars['Email']['input'];
  telefoonnummer: Scalars['String']['input'];
  motivatie: Scalars['String']['input'];
  cv: Scalars['Upload']['input'];
  vacature: Scalars['String']['input'];
}>;


export type ApplyMutation = { __typename?: 'Mutation', apply?: boolean | null };

export type SubmitContactFormMutationVariables = Exact<{
  question: Scalars['String']['input'];
  company: Scalars['String']['input'];
  name: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  phone: Scalars['String']['input'];
}>;


export type SubmitContactFormMutation = { __typename?: 'Mutation', submitContactForm?: boolean | null };

export type AmbitionQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type AmbitionQuery = { __typename?: 'Query', ambition?: { __typename?: 'Werkveld', id: number | string, active_image?: Image | null, color?: string | null, downloads?: Array<File | null> | null, icon?: string | null, slug: string, short_text?: string | null, title: string, content_text?: string | null, content_title?: string | null, text?: string | null, children: Array<{ __typename?: 'Werkveld', id: number | string, active_image?: Image | null, downloads?: Array<File | null> | null, short_text?: string | null, slug: string, title: string, text?: string | null }> } | null };

export type AmbitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AmbitionsQuery = { __typename?: 'Query', ambitions: Array<{ __typename?: 'Werkveld', id: number | string, slug: string, title: string, icon?: string | null, description?: string | null, color?: string | null, active_image?: Image | null, text?: string | null, parent?: { __typename?: 'Werkveld', id: number | string, color?: string | null } | null }> };

export type ArticleQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Bericht', id: number | string, slug: string, title: string, active_image?: Image | null, extra_title?: string | null, extra_text?: string | null, text: string, date: string, ambition?: { __typename?: 'Werkveld', id: number | string, title: string, color?: string | null, slug: string } | null } | null };

export type ArticlesQueryVariables = Exact<{
  ambition_id?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles?: { __typename?: 'BerichtPagination', last_page: number, data?: Array<{ __typename?: 'Bericht', id: number | string, slug: string, title: string, active_image?: Image | null, created_at?: string | null, text: string, date: string, ambition?: { __typename?: 'Werkveld', id: number | string, title: string, color?: string | null } | null } | null> | null } | null };

export type BusinessCouncilsQueryVariables = Exact<{ [key: string]: never; }>;


export type BusinessCouncilsQuery = { __typename?: 'Query', businessCouncils: Array<{ __typename?: 'Page', active_image?: Image | null, title: string, slug: string, template?: { __typename?: 'TemplateAboutUs' } | { __typename?: 'TemplateContact' } | { __typename?: 'TemplateEngels' } | { __typename?: 'TemplateHome' } | { __typename?: 'TemplateIntroimage' } | { __typename?: 'TemplateIntrosmallimage' } | { __typename?: 'TemplateIntrowithoutimage' } | { __typename?: 'TemplateLidWorden' } | { __typename?: 'TemplateStandaard', text?: string | null } | { __typename?: 'TemplateWithoutintrolargeimage' } | null }> };

export type EventQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type EventQuery = { __typename?: 'Query', event?: { __typename?: 'Evenement', id: number | string, title: string, slug: string, active_image?: Image | null, content_title?: string | null, content_text?: string | null, start_date: string, external_url?: string | null, text?: string | null } | null };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Evenement', id: number | string, title: string, slug: string, start_date: string, external_url?: string | null }> };

export type CommitteesQueryVariables = Exact<{ [key: string]: never; }>;


export type CommitteesQuery = { __typename?: 'Query', committees: Array<{ __typename?: 'Page', active_image?: Image | null, title: string, slug: string, template?: { __typename?: 'TemplateAboutUs' } | { __typename?: 'TemplateContact' } | { __typename?: 'TemplateEngels' } | { __typename?: 'TemplateHome' } | { __typename?: 'TemplateIntroimage' } | { __typename?: 'TemplateIntrosmallimage' } | { __typename?: 'TemplateIntrowithoutimage' } | { __typename?: 'TemplateLidWorden' } | { __typename?: 'TemplateStandaard', text?: string | null } | { __typename?: 'TemplateWithoutintrolargeimage' } | null }> };

export type EmployeesQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeesQuery = { __typename?: 'Query', employees: Array<{ __typename?: 'Medewerker', title: string, active_image?: Image | null, last_name: string, company?: string | null, department: string, function?: string | null, mobile_number?: string | null, email?: string | null }> };

export type GlobalSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GlobalSearchQuery = { __typename?: 'Query', globalSearch?: { __typename?: 'GlobalSearch', ambitions: Array<{ __typename?: 'Werkveld', id: number | string, slug: string, title: string, color?: string | null, active_image?: Image | null, text?: string | null, parent?: { __typename?: 'Werkveld', slug: string, title: string } | null }>, news: Array<{ __typename?: 'Bericht', id: number | string, slug: string, title: string, active_image?: Image | null, created_at?: string | null, text: string, date: string, ambition?: { __typename?: 'Werkveld', id: number | string, title: string, slug: string } | null }>, events: Array<{ __typename?: 'Evenement', id: number | string, title: string, start_date: string, external_url?: string | null }> } | null };

export type MemberQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type MemberQuery = { __typename?: 'Query', member?: { __typename?: 'Bedrijf', id: number | string, slug: string, title: string, active_image?: Image | null, website?: string | null, address?: string | null, zipcode?: string | null, city?: string | null, country?: string | null, mobile_number?: string | null, email?: string | null, sectors: Array<{ __typename?: 'Sector', title: string } | null> } | null };

export type MembersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  sector?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MembersQuery = { __typename?: 'Query', members?: { __typename?: 'BedrijfPagination', per_page: number, total: number, data?: Array<{ __typename?: 'Bedrijf', id: number | string, slug: string, title: string, active_image?: Image | null, website?: string | null, address?: string | null, zipcode?: string | null, city?: string | null, country?: string | null, mobile_number?: string | null, email?: string | null, sectors: Array<{ __typename?: 'Sector', title: string } | null> } | null> | null } | null };

export type MenusQueryVariables = Exact<{
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type MenusQuery = { __typename?: 'Query', menus?: Array<{ __typename?: 'Menu', name?: string | null, items?: Array<{ __typename?: 'MenuItem', text?: string | null, scope?: string | null, url?: string | null, children?: Array<{ __typename?: 'MenuItem', text?: string | null, scope?: string | null, url?: string | null, children?: Array<{ __typename?: 'MenuItem', text?: string | null, scope?: string | null, url?: string | null } | null> | null } | null> | null } | null> | null } | null> | null };

export type NetworkingClubsQueryVariables = Exact<{ [key: string]: never; }>;


export type NetworkingClubsQuery = { __typename?: 'Query', networkingClubs: Array<{ __typename?: 'Page', active_image?: Image | null, title: string, slug: string, template?: { __typename?: 'TemplateAboutUs' } | { __typename?: 'TemplateContact' } | { __typename?: 'TemplateEngels' } | { __typename?: 'TemplateHome' } | { __typename?: 'TemplateIntroimage' } | { __typename?: 'TemplateIntrosmallimage' } | { __typename?: 'TemplateIntrowithoutimage' } | { __typename?: 'TemplateLidWorden' } | { __typename?: 'TemplateStandaard', text?: string | null } | { __typename?: 'TemplateWithoutintrolargeimage' } | null }> };

export type NetworkingGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type NetworkingGroupsQuery = { __typename?: 'Query', networkingGroups: Array<{ __typename?: 'Page', active_image?: Image | null, title: string, slug: string, template?: { __typename?: 'TemplateAboutUs' } | { __typename?: 'TemplateContact' } | { __typename?: 'TemplateEngels' } | { __typename?: 'TemplateHome' } | { __typename?: 'TemplateIntroimage' } | { __typename?: 'TemplateIntrosmallimage' } | { __typename?: 'TemplateIntrowithoutimage' } | { __typename?: 'TemplateLidWorden' } | { __typename?: 'TemplateStandaard', text?: string | null } | { __typename?: 'TemplateWithoutintrolargeimage' } | null }> };

export type PageQueryVariables = Exact<{
  segments: Scalars['String']['input'];
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', url: string, title: string, slug: string, seo?: { __typename?: 'Seo', meta_title?: string | null, meta_description?: string | null, meta_index?: boolean | null, meta_follow?: boolean | null } | null, template?: { __typename?: 'TemplateAboutUs', content_title?: string | null, member_title?: string | null, member_text?: string | null, vacancies_title?: string | null, vacancies_text?: string | null, about_text?: string | null, extra_title?: string | null, extra_text?: string | null, circle_title?: string | null, circle_text?: string | null } | { __typename?: 'TemplateContact', tekst: string, published?: boolean | null, updated_at?: string | null, text: string } | { __typename?: 'TemplateEngels' } | { __typename?: 'TemplateHome', teaser: string, home_text: string, sector_title?: string | null, sector_text?: string | null, points?: Array<{ __typename?: 'Point', title: string, top?: number | null, left?: number | null, top_open?: number | null, left_open?: number | null, description?: string | null } | null> | null } | { __typename?: 'TemplateIntroimage', content_title?: string | null, intro?: string | null, description?: string | null } | { __typename?: 'TemplateIntrosmallimage', content_title?: string | null, intro?: string | null, description?: string | null } | { __typename?: 'TemplateIntrowithoutimage', content_title?: string | null, intro?: string | null, description?: string | null } | { __typename?: 'TemplateLidWorden' } | { __typename?: 'TemplateStandaard', content_title?: string | null, standard_text?: string | null } | { __typename?: 'TemplateWithoutintrolargeimage', content_title?: string | null, description?: string | null } | null } | null };

export type QuestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionsQuery = { __typename?: 'Query', questions: Array<{ __typename?: 'Vraag', id: number | string, title: string, url?: string | null, answer: string }> };

export type SectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type SectorsQuery = { __typename?: 'Query', sectors: Array<{ __typename?: 'Sector', id: number | string, title: string }> };

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = { __typename?: 'Query', settings?: Array<{ __typename?: 'Setting', key: string, value?: string | null } | null> | null };

export type VacanciesQueryVariables = Exact<{ [key: string]: never; }>;


export type VacanciesQuery = { __typename?: 'Query', vacancies: Array<{ __typename?: 'Vacature', slug: string, title: string, url: string, reactie?: string | null, description?: string | null, hours?: string | null, education_degree?: string | null, image?: Image | null, employment?: string | null, working_environment?: string | null, work?: string | null, expectations?: string | null, offer?: string | null, recruiter?: { __typename?: 'Medewerker', id: number | string, active_image?: Image | null, email?: string | null, first_name: string, last_name: string, function?: string | null, phone?: string | null } | null }> };

export type VacancyQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type VacancyQuery = { __typename?: 'Query', vacancy?: { __typename?: 'Vacature', slug: string, title: string, url: string, reactie?: string | null, description?: string | null, hours?: string | null, education_degree?: string | null, image?: Image | null, employment?: string | null, working_environment?: string | null, work?: string | null, expectations?: string | null, offer?: string | null, recruiter?: { __typename?: 'Medewerker', id: number | string, active_image?: Image | null, email?: string | null, first_name: string, last_name: string, function?: string | null, phone?: string | null } | null } | null };


export const ApplyDocument = gql`
    mutation apply($naam: String!, $title: Email!, $telefoonnummer: String!, $motivatie: String!, $cv: Upload!, $vacature: String!) {
  apply(
    naam: $naam
    title: $title
    telefoonnummer: $telefoonnummer
    motivatie: $motivatie
    cv: $cv
    vacature: $vacature
  )
}
    `;

/**
 * __useApplyMutation__
 *
 * To run a mutation, you first call `useApplyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useApplyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useApplyMutation({
 *   variables: {
 *     naam: // value for 'naam'
 *     title: // value for 'title'
 *     telefoonnummer: // value for 'telefoonnummer'
 *     motivatie: // value for 'motivatie'
 *     cv: // value for 'cv'
 *     vacature: // value for 'vacature'
 *   },
 * });
 */
export function useApplyMutation(options: VueApolloComposable.UseMutationOptions<ApplyMutation, ApplyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ApplyMutation, ApplyMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ApplyMutation, ApplyMutationVariables>(ApplyDocument, options);
}
export type ApplyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ApplyMutation, ApplyMutationVariables>;
export const SubmitContactFormDocument = gql`
    mutation submitContactForm($question: String!, $company: String!, $name: String!, $email: Email!, $phone: String!) {
  submitContactForm(
    question: $question
    company: $company
    name: $name
    phone: $phone
    email: $email
  )
}
    `;

/**
 * __useSubmitContactFormMutation__
 *
 * To run a mutation, you first call `useSubmitContactFormMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitContactFormMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitContactFormMutation({
 *   variables: {
 *     question: // value for 'question'
 *     company: // value for 'company'
 *     name: // value for 'name'
 *     email: // value for 'email'
 *     phone: // value for 'phone'
 *   },
 * });
 */
export function useSubmitContactFormMutation(options: VueApolloComposable.UseMutationOptions<SubmitContactFormMutation, SubmitContactFormMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitContactFormMutation, SubmitContactFormMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SubmitContactFormMutation, SubmitContactFormMutationVariables>(SubmitContactFormDocument, options);
}
export type SubmitContactFormMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SubmitContactFormMutation, SubmitContactFormMutationVariables>;
export const AmbitionDocument = gql`
    query ambition($slug: String!) {
  ambition(slug: $slug) {
    id
    active_image(dpr: 2, w: 1216, h: 684)
    children {
      id
      active_image(dpr: 2, w: 252, h: 175)
      downloads
      short_text
      text: tekst
      slug
      title
    }
    color
    content_text: content_tekst
    content_title: content_titel
    downloads
    icon
    slug
    text: tekst
    short_text
    title
  }
}
    `;

/**
 * __useAmbitionQuery__
 *
 * To run a query within a Vue component, call `useAmbitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmbitionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAmbitionQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useAmbitionQuery(variables: AmbitionQueryVariables | VueCompositionApi.Ref<AmbitionQueryVariables> | ReactiveFunction<AmbitionQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmbitionQuery, AmbitionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmbitionQuery, AmbitionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmbitionQuery, AmbitionQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AmbitionQuery, AmbitionQueryVariables>(AmbitionDocument, variables, options);
}
export function useAmbitionLazyQuery(variables?: AmbitionQueryVariables | VueCompositionApi.Ref<AmbitionQueryVariables> | ReactiveFunction<AmbitionQueryVariables>, options: VueApolloComposable.UseQueryOptions<AmbitionQuery, AmbitionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmbitionQuery, AmbitionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmbitionQuery, AmbitionQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AmbitionQuery, AmbitionQueryVariables>(AmbitionDocument, variables, options);
}
export type AmbitionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AmbitionQuery, AmbitionQueryVariables>;
export const AmbitionsDocument = gql`
    query ambitions {
  ambitions {
    id
    slug
    title
    icon
    description
    text: tekst
    color
    active_image(dpr: 2, w: 802, h: 404)
    parent {
      id
      color
    }
  }
}
    `;

/**
 * __useAmbitionsQuery__
 *
 * To run a query within a Vue component, call `useAmbitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmbitionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAmbitionsQuery();
 */
export function useAmbitionsQuery(options: VueApolloComposable.UseQueryOptions<AmbitionsQuery, AmbitionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmbitionsQuery, AmbitionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmbitionsQuery, AmbitionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AmbitionsQuery, AmbitionsQueryVariables>(AmbitionsDocument, {}, options);
}
export function useAmbitionsLazyQuery(options: VueApolloComposable.UseQueryOptions<AmbitionsQuery, AmbitionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AmbitionsQuery, AmbitionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AmbitionsQuery, AmbitionsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AmbitionsQuery, AmbitionsQueryVariables>(AmbitionsDocument, {}, options);
}
export type AmbitionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AmbitionsQuery, AmbitionsQueryVariables>;
export const ArticleDocument = gql`
    query article($slug: String!) {
  article(slug: $slug) {
    id
    slug
    title
    active_image(w: 1280, fit: "crop", dpr: 2)
    text: tekst
    date: datum
    extra_title
    extra_text
    ambition {
      id
      title
      color
      slug
    }
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a Vue component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useArticleQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useArticleQuery(variables: ArticleQueryVariables | VueCompositionApi.Ref<ArticleQueryVariables> | ReactiveFunction<ArticleQueryVariables>, options: VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, variables, options);
}
export function useArticleLazyQuery(variables?: ArticleQueryVariables | VueCompositionApi.Ref<ArticleQueryVariables> | ReactiveFunction<ArticleQueryVariables>, options: VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, variables, options);
}
export type ArticleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query articles($ambition_id: ID, $page: Int, $limit: Int) {
  articles(ambition_id: $ambition_id, page: $page, limit: $limit) {
    data {
      id
      slug
      title
      active_image(w: 320, fit: "crop", dpr: 2)
      text: tekst
      date: datum
      created_at
      ambition {
        id
        title
        color
      }
    }
    last_page
  }
}
    `;

/**
 * __useArticlesQuery__
 *
 * To run a query within a Vue component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useArticlesQuery({
 *   ambition_id: // value for 'ambition_id'
 *   page: // value for 'page'
 *   limit: // value for 'limit'
 * });
 */
export function useArticlesQuery(variables: ArticlesQueryVariables | VueCompositionApi.Ref<ArticlesQueryVariables> | ReactiveFunction<ArticlesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, variables, options);
}
export function useArticlesLazyQuery(variables: ArticlesQueryVariables | VueCompositionApi.Ref<ArticlesQueryVariables> | ReactiveFunction<ArticlesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, variables, options);
}
export type ArticlesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ArticlesQuery, ArticlesQueryVariables>;
export const BusinessCouncilsDocument = gql`
    query businessCouncils {
  businessCouncils {
    active_image
    title
    slug
    template {
      ... on TemplateStandaard {
        text: tekst
      }
    }
  }
}
    `;

/**
 * __useBusinessCouncilsQuery__
 *
 * To run a query within a Vue component, call `useBusinessCouncilsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessCouncilsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBusinessCouncilsQuery();
 */
export function useBusinessCouncilsQuery(options: VueApolloComposable.UseQueryOptions<BusinessCouncilsQuery, BusinessCouncilsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BusinessCouncilsQuery, BusinessCouncilsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BusinessCouncilsQuery, BusinessCouncilsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BusinessCouncilsQuery, BusinessCouncilsQueryVariables>(BusinessCouncilsDocument, {}, options);
}
export function useBusinessCouncilsLazyQuery(options: VueApolloComposable.UseQueryOptions<BusinessCouncilsQuery, BusinessCouncilsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BusinessCouncilsQuery, BusinessCouncilsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BusinessCouncilsQuery, BusinessCouncilsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<BusinessCouncilsQuery, BusinessCouncilsQueryVariables>(BusinessCouncilsDocument, {}, options);
}
export type BusinessCouncilsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BusinessCouncilsQuery, BusinessCouncilsQueryVariables>;
export const EventDocument = gql`
    query event($slug: String!) {
  event(slug: $slug) {
    id
    title
    slug
    start_date: startdatum
    external_url: externe_url
    active_image
    text: tekst
    content_title
    content_text
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a Vue component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useEventQuery(variables: EventQueryVariables | VueCompositionApi.Ref<EventQueryVariables> | ReactiveFunction<EventQueryVariables>, options: VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EventQuery, EventQueryVariables>(EventDocument, variables, options);
}
export function useEventLazyQuery(variables?: EventQueryVariables | VueCompositionApi.Ref<EventQueryVariables> | ReactiveFunction<EventQueryVariables>, options: VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, variables, options);
}
export type EventQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EventQuery, EventQueryVariables>;
export const EventsDocument = gql`
    query events {
  events {
    id
    title
    slug
    start_date: startdatum
    external_url: externe_url
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a Vue component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventsQuery();
 */
export function useEventsQuery(options: VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, {}, options);
}
export function useEventsLazyQuery(options: VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, {}, options);
}
export type EventsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EventsQuery, EventsQueryVariables>;
export const CommitteesDocument = gql`
    query committees {
  committees {
    active_image
    title
    slug
    template {
      ... on TemplateStandaard {
        text: tekst
      }
    }
  }
}
    `;

/**
 * __useCommitteesQuery__
 *
 * To run a query within a Vue component, call `useCommitteesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommitteesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCommitteesQuery();
 */
export function useCommitteesQuery(options: VueApolloComposable.UseQueryOptions<CommitteesQuery, CommitteesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CommitteesQuery, CommitteesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CommitteesQuery, CommitteesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CommitteesQuery, CommitteesQueryVariables>(CommitteesDocument, {}, options);
}
export function useCommitteesLazyQuery(options: VueApolloComposable.UseQueryOptions<CommitteesQuery, CommitteesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CommitteesQuery, CommitteesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CommitteesQuery, CommitteesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CommitteesQuery, CommitteesQueryVariables>(CommitteesDocument, {}, options);
}
export type CommitteesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CommitteesQuery, CommitteesQueryVariables>;
export const EmployeesDocument = gql`
    query employees {
  employees {
    title
    last_name: achternaam
    active_image(dpr: 2, w: 321, h: 225)
    company: bedrijf
    department: afdeling
    function: functie
    mobile_number: telefoonnummer
    email: e_mailadres
  }
}
    `;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a Vue component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEmployeesQuery();
 */
export function useEmployeesQuery(options: VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, {}, options);
}
export function useEmployeesLazyQuery(options: VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EmployeesQuery, EmployeesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, {}, options);
}
export type EmployeesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EmployeesQuery, EmployeesQueryVariables>;
export const GlobalSearchDocument = gql`
    query globalSearch($query: String!, $limit: Int) {
  globalSearch(query: $query, limit: $limit) {
    ambitions {
      id
      slug
      title
      parent {
        slug
      }
      text: tekst
      color
      active_image(dpr: 2, w: 802, h: 404)
      parent {
        title
        slug
      }
    }
    news {
      id
      slug
      title
      active_image(w: 320, fit: "crop", dpr: 2)
      text: tekst
      date: datum
      created_at
      ambition {
        id
        title
        slug
      }
    }
    events {
      id
      title
      start_date: startdatum
      external_url: externe_url
    }
  }
}
    `;

/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a Vue component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGlobalSearchQuery({
 *   query: // value for 'query'
 *   limit: // value for 'limit'
 * });
 */
export function useGlobalSearchQuery(variables: GlobalSearchQueryVariables | VueCompositionApi.Ref<GlobalSearchQueryVariables> | ReactiveFunction<GlobalSearchQueryVariables>, options: VueApolloComposable.UseQueryOptions<GlobalSearchQuery, GlobalSearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GlobalSearchQuery, GlobalSearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GlobalSearchQuery, GlobalSearchQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(GlobalSearchDocument, variables, options);
}
export function useGlobalSearchLazyQuery(variables?: GlobalSearchQueryVariables | VueCompositionApi.Ref<GlobalSearchQueryVariables> | ReactiveFunction<GlobalSearchQueryVariables>, options: VueApolloComposable.UseQueryOptions<GlobalSearchQuery, GlobalSearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GlobalSearchQuery, GlobalSearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GlobalSearchQuery, GlobalSearchQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(GlobalSearchDocument, variables, options);
}
export type GlobalSearchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GlobalSearchQuery, GlobalSearchQueryVariables>;
export const MemberDocument = gql`
    query member($slug: String!) {
  member(slug: $slug) {
    id
    slug
    title
    active_image(dpr: 2, w: 321, h: 225)
    sectors: sectoren {
      title
    }
    address: adres
    zipcode: postcode
    city: plaats
    country: land
    mobile_number: telefoonnummer
    email: e_mailadres
    website
  }
}
    `;

/**
 * __useMemberQuery__
 *
 * To run a query within a Vue component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMemberQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useMemberQuery(variables: MemberQueryVariables | VueCompositionApi.Ref<MemberQueryVariables> | ReactiveFunction<MemberQueryVariables>, options: VueApolloComposable.UseQueryOptions<MemberQuery, MemberQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MemberQuery, MemberQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MemberQuery, MemberQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, variables, options);
}
export function useMemberLazyQuery(variables?: MemberQueryVariables | VueCompositionApi.Ref<MemberQueryVariables> | ReactiveFunction<MemberQueryVariables>, options: VueApolloComposable.UseQueryOptions<MemberQuery, MemberQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MemberQuery, MemberQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MemberQuery, MemberQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, variables, options);
}
export type MemberQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MemberQuery, MemberQueryVariables>;
export const MembersDocument = gql`
    query members($query: String, $sector: [String!], $page: Int) {
  members(query: $query, sector: $sector, page: $page) {
    data {
      id
      slug
      title
      active_image(dpr: 2, w: 321, h: 225)
      sectors: sectoren {
        title
      }
      address: adres
      zipcode: postcode
      city: plaats
      country: land
      mobile_number: telefoonnummer
      email: e_mailadres
      website
    }
    per_page
    total
  }
}
    `;

/**
 * __useMembersQuery__
 *
 * To run a query within a Vue component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMembersQuery({
 *   query: // value for 'query'
 *   sector: // value for 'sector'
 *   page: // value for 'page'
 * });
 */
export function useMembersQuery(variables: MembersQueryVariables | VueCompositionApi.Ref<MembersQueryVariables> | ReactiveFunction<MembersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<MembersQuery, MembersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MembersQuery, MembersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MembersQuery, MembersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, variables, options);
}
export function useMembersLazyQuery(variables: MembersQueryVariables | VueCompositionApi.Ref<MembersQueryVariables> | ReactiveFunction<MembersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<MembersQuery, MembersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MembersQuery, MembersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MembersQuery, MembersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, variables, options);
}
export type MembersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MembersQuery, MembersQueryVariables>;
export const MenusDocument = gql`
    query menus($names: [String]) {
  menus(names: $names) {
    name
    items {
      text
      scope
      url
      children {
        text
        scope
        url
        children {
          text
          scope
          url
        }
      }
    }
  }
}
    `;

/**
 * __useMenusQuery__
 *
 * To run a query within a Vue component, call `useMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMenusQuery({
 *   names: // value for 'names'
 * });
 */
export function useMenusQuery(variables: MenusQueryVariables | VueCompositionApi.Ref<MenusQueryVariables> | ReactiveFunction<MenusQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<MenusQuery, MenusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MenusQuery, MenusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MenusQuery, MenusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MenusQuery, MenusQueryVariables>(MenusDocument, variables, options);
}
export function useMenusLazyQuery(variables: MenusQueryVariables | VueCompositionApi.Ref<MenusQueryVariables> | ReactiveFunction<MenusQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<MenusQuery, MenusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MenusQuery, MenusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MenusQuery, MenusQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MenusQuery, MenusQueryVariables>(MenusDocument, variables, options);
}
export type MenusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MenusQuery, MenusQueryVariables>;
export const NetworkingClubsDocument = gql`
    query networkingClubs {
  networkingClubs {
    active_image
    title
    slug
    template {
      ... on TemplateStandaard {
        text: tekst
      }
    }
  }
}
    `;

/**
 * __useNetworkingClubsQuery__
 *
 * To run a query within a Vue component, call `useNetworkingClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkingClubsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useNetworkingClubsQuery();
 */
export function useNetworkingClubsQuery(options: VueApolloComposable.UseQueryOptions<NetworkingClubsQuery, NetworkingClubsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<NetworkingClubsQuery, NetworkingClubsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<NetworkingClubsQuery, NetworkingClubsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<NetworkingClubsQuery, NetworkingClubsQueryVariables>(NetworkingClubsDocument, {}, options);
}
export function useNetworkingClubsLazyQuery(options: VueApolloComposable.UseQueryOptions<NetworkingClubsQuery, NetworkingClubsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<NetworkingClubsQuery, NetworkingClubsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<NetworkingClubsQuery, NetworkingClubsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<NetworkingClubsQuery, NetworkingClubsQueryVariables>(NetworkingClubsDocument, {}, options);
}
export type NetworkingClubsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<NetworkingClubsQuery, NetworkingClubsQueryVariables>;
export const NetworkingGroupsDocument = gql`
    query networkingGroups {
  networkingGroups {
    active_image
    title
    slug
    template {
      ... on TemplateStandaard {
        text: tekst
      }
    }
  }
}
    `;

/**
 * __useNetworkingGroupsQuery__
 *
 * To run a query within a Vue component, call `useNetworkingGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkingGroupsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useNetworkingGroupsQuery();
 */
export function useNetworkingGroupsQuery(options: VueApolloComposable.UseQueryOptions<NetworkingGroupsQuery, NetworkingGroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<NetworkingGroupsQuery, NetworkingGroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<NetworkingGroupsQuery, NetworkingGroupsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<NetworkingGroupsQuery, NetworkingGroupsQueryVariables>(NetworkingGroupsDocument, {}, options);
}
export function useNetworkingGroupsLazyQuery(options: VueApolloComposable.UseQueryOptions<NetworkingGroupsQuery, NetworkingGroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<NetworkingGroupsQuery, NetworkingGroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<NetworkingGroupsQuery, NetworkingGroupsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<NetworkingGroupsQuery, NetworkingGroupsQueryVariables>(NetworkingGroupsDocument, {}, options);
}
export type NetworkingGroupsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<NetworkingGroupsQuery, NetworkingGroupsQueryVariables>;
export const PageDocument = gql`
    query page($segments: String!) {
  page(segments: $segments) {
    url
    title
    slug
    seo {
      meta_title
      meta_description
      meta_index
      meta_follow
    }
    template {
      ... on TemplateStandaard {
        standard_text: tekst
        content_title
      }
      ... on TemplateHome {
        teaser
        home_text: tekst
        sector_title: sector_titel
        sector_text: sector_tekst
        points: punten {
          title
          description: beschrijving
          top
          left
          top_open
          left_open
        }
      }
      ... on TemplateAboutUs {
        content_title
        about_text: tekst
        extra_title: extra_titel
        extra_text: extra_tekst
        circle_title: cirkel_titel
        circle_text: cirkel_tekst
        member_title
        member_text
        vacancies_title
        vacancies_text
      }
      ... on TemplateIntroimage {
        content_title
        intro
        description
      }
      ... on TemplateIntrosmallimage {
        content_title
        intro
        description
      }
      ... on TemplateIntrowithoutimage {
        content_title
        intro
        description
      }
      ... on TemplateWithoutintrolargeimage {
        content_title
        description
      }
      ... on TemplateContact {
        text: tekst
        tekst
        published
        updated_at
      }
    }
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a Vue component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageQuery({
 *   segments: // value for 'segments'
 * });
 */
export function usePageQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export function usePageLazyQuery(variables?: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export type PageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageQuery, PageQueryVariables>;
export const QuestionsDocument = gql`
    query questions {
  questions {
    id
    title
    answer: antwoord
    url
  }
}
    `;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a Vue component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useQuestionsQuery();
 */
export function useQuestionsQuery(options: VueApolloComposable.UseQueryOptions<QuestionsQuery, QuestionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<QuestionsQuery, QuestionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<QuestionsQuery, QuestionsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, {}, options);
}
export function useQuestionsLazyQuery(options: VueApolloComposable.UseQueryOptions<QuestionsQuery, QuestionsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<QuestionsQuery, QuestionsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<QuestionsQuery, QuestionsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, {}, options);
}
export type QuestionsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<QuestionsQuery, QuestionsQueryVariables>;
export const SectorsDocument = gql`
    query sectors {
  sectors {
    id
    title
  }
}
    `;

/**
 * __useSectorsQuery__
 *
 * To run a query within a Vue component, call `useSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectorsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSectorsQuery();
 */
export function useSectorsQuery(options: VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SectorsQuery, SectorsQueryVariables>(SectorsDocument, {}, options);
}
export function useSectorsLazyQuery(options: VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SectorsQuery, SectorsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SectorsQuery, SectorsQueryVariables>(SectorsDocument, {}, options);
}
export type SectorsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SectorsQuery, SectorsQueryVariables>;
export const SettingsDocument = gql`
    query settings {
  settings {
    key
    value
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a Vue component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSettingsQuery();
 */
export function useSettingsQuery(options: VueApolloComposable.UseQueryOptions<SettingsQuery, SettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SettingsQuery, SettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SettingsQuery, SettingsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, {}, options);
}
export function useSettingsLazyQuery(options: VueApolloComposable.UseQueryOptions<SettingsQuery, SettingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SettingsQuery, SettingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SettingsQuery, SettingsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, {}, options);
}
export type SettingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SettingsQuery, SettingsQueryVariables>;
export const VacanciesDocument = gql`
    query vacancies {
  vacancies {
    slug
    title
    description: beschrijving
    hours: aantal_uren
    education_degree: opleidingsniveau
    image: active_image
    url
    employment: dienstverband
    working_environment: werkomgeving
    work: wat_ga_je_doen
    expectations: verwachtingen
    offer: wij_bieden
    reactie
    recruiter: beheerder {
      id
      active_image
      email: e_mailadres
      first_name: title
      last_name: achternaam
      function: functie
      phone: telefoonnummer
    }
  }
}
    `;

/**
 * __useVacanciesQuery__
 *
 * To run a query within a Vue component, call `useVacanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacanciesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVacanciesQuery();
 */
export function useVacanciesQuery(options: VueApolloComposable.UseQueryOptions<VacanciesQuery, VacanciesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacanciesQuery, VacanciesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacanciesQuery, VacanciesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VacanciesQuery, VacanciesQueryVariables>(VacanciesDocument, {}, options);
}
export function useVacanciesLazyQuery(options: VueApolloComposable.UseQueryOptions<VacanciesQuery, VacanciesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacanciesQuery, VacanciesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacanciesQuery, VacanciesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VacanciesQuery, VacanciesQueryVariables>(VacanciesDocument, {}, options);
}
export type VacanciesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VacanciesQuery, VacanciesQueryVariables>;
export const VacancyDocument = gql`
    query vacancy($slug: String!) {
  vacancy(slug: $slug) {
    slug
    title
    description: beschrijving
    hours: aantal_uren
    education_degree: opleidingsniveau
    image: active_image
    url
    employment: dienstverband
    working_environment: werkomgeving
    work: wat_ga_je_doen
    expectations: verwachtingen
    offer: wij_bieden
    reactie
    recruiter: beheerder {
      id
      active_image
      email: e_mailadres
      first_name: title
      last_name: achternaam
      function: functie
      phone: telefoonnummer
    }
  }
}
    `;

/**
 * __useVacancyQuery__
 *
 * To run a query within a Vue component, call `useVacancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacancyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVacancyQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useVacancyQuery(variables: VacancyQueryVariables | VueCompositionApi.Ref<VacancyQueryVariables> | ReactiveFunction<VacancyQueryVariables>, options: VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VacancyQuery, VacancyQueryVariables>(VacancyDocument, variables, options);
}
export function useVacancyLazyQuery(variables?: VacancyQueryVariables | VueCompositionApi.Ref<VacancyQueryVariables> | ReactiveFunction<VacancyQueryVariables>, options: VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VacancyQuery, VacancyQueryVariables>(VacancyDocument, variables, options);
}
export type VacancyQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VacancyQuery, VacancyQueryVariables>;